import React from 'react';
import { render } from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import configureStore from './store/configureStore';
import Root from './Root';
import * as Sentry from '@sentry/browser';

const store = configureStore();
const rootElement = document.getElementById('a_m_p');

Sentry.init({ dsn: 'https://61ed62c4f4e9441db6c0fa551760eb7f@sentry.io/1547458' });

render(<Root store={store} />, rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
