import React, { lazy, Suspense, Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import Loader from '../components/Loader';
import './index.scss';
import AnimatedTransition from '../components/AnimatedTransition';

const OpenSource = lazy(() => import(/* webpackChunkName: "OpenSource" */ '../containers/OpenSource'));
const Home = lazy(() => import(/* webpackChunkName: "Home" */ '../components/Home'));
const About = lazy(() => import(/* webpackChunkName: "About" */ '../components/About'));
const Header = lazy(() => import(/* webpackChunkName: "Header" */ '../components/Header'));
const Container = lazy(() => import(/* webpackChunkName: "Container" */ '../components/Container'));

// const OpenSourceProject = lazy(() =>
// 	import(/* webpackChunkName: "OpenSourceProject" */ '../containers/OpenSourceProject')
// );

const Lab = lazy(() => import(/* webpackChunkName: "Lab" */ '../components/Lab'));
const Contact = lazy(() => import(/* webpackChunkName: "Contact" */ '../components/Contact'));

const Graph = lazy(() => import(/* webpackChunkName: "Graph" */ '../containers/Projects/Graph'));
const VisReact = lazy(() => import(/* webpackChunkName: "VisReact" */ '../containers/Projects/VisReact'));
const ReactImgr = lazy(() => import(/* webpackChunkName: "ReactImgr" */ '../containers/Projects/ReactImgr'));
const NetSliderContainer = lazy(() =>
	import(/* webpackChunkName: "NetSlider" */ '../containers/Projects/NetSliderContainer')
);

export default class Router extends Component {
	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			if (window.ga) {
				window.ga('set', 'page', window.location.pathname + window.location.search);
				window.ga('send', 'pageview', window.location.pathname + window.location.search);
			}
		}
	}
	render() {
		return (
			<div className='wrapper'>
				<Suspense fallback={<Loader />}>
					<Header />
					<Container>
						<Switch>
							<Route exact path='/' render={() => <Home />} />
							<Route exact path='/opensource' render={() => <OpenSource />} />
							{/* <Route exact path='/opensource/:project' render={() => <OpenSourceProject />} /> */}
							<Route exact path='/opensource/react-circular-graph' render={() => <Graph />} />
							<Route exact path='/opensource/vis-react' render={() => <VisReact />} />
							<Route exact path='/opensource/react-imgr' render={() => <ReactImgr />} />
							<Route exact path='/opensource/netslider' render={() => <NetSliderContainer />} />
							<Route exact path='/about' render={() => <About />} />
							<Route exact path='/animatedtransition' render={() => <AnimatedTransition />} />
							<Route exact path='/lab' render={() => <Lab />} />
							<Route exact path='/contact' render={() => <Contact />} />
						</Switch>
					</Container>
				</Suspense>
			</div>
		);
	}
}

// const constants = {
// 	'react-circular-graph': 'Graph',
// 	'vis-react': 'VisReact',
// 	'react-imgr': 'ReactImgr',
// 	netslider: 'NetSliderContainer'
// };
