import { createStore, applyMiddleware } from 'redux';
// import thunk from 'redux-thunk';
import reducer from '../reducers';
import rootSaga from '../sagas';

// import ReduxPromise from 'redux-promise';

import createSagaMiddleware from 'redux-saga';
export const sagaMiddleware = createSagaMiddleware();

// create a store that has redux-saga middleware enabled
// const createStoreWithMiddleware = applyMiddleware(sagaMiddleware)(createStore);

// export default function configureStore() {
// 	sagaMiddleware.run(rootSaga);
// 	return createStoreWithMiddleware(reducer);
// }

export default function configureStore(initialState) {
	const sagaMiddleware = createSagaMiddleware();
	const store = createStore(reducer, initialState, applyMiddleware(sagaMiddleware));

	sagaMiddleware.run(rootSaga);
	return store;
}
