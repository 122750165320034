/**
 * @module Sagas/Header
 * @desc App
 */
import axios from 'axios';
import { all, put, takeLatest, call } from 'redux-saga/effects';

import { receiveInitialFetch } from '../../actions/Header';
import { REQUEST_INITIALFETCH } from '../../actionTypes/Header';
import { makeObject } from '../../Utils';

export function initialFetchApi(sheetId = 'Home') {
	const {REACT_APP_KEY : KEY = '', REACT_APP_SPREADSHEET_ID : SPREADSHEET_ID = ''} = process.env || {}
	// const url = `https://mockjson-co.herokuapp.com/bin/6159bc785cab97002fcea965`
	const url = `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values/${sheetId}?key=${KEY}`
	return axios.get(url).then(response => makeObject(response.data));
}

export function* initialFetch(node) {
	const initialFetch = yield call(initialFetchApi, node.id);
	yield put(receiveInitialFetch(initialFetch));
}

// Saga function that is initiated in the beginning to be able to listen to REQUESTINITIALFETCH  action
export default function* root() {
	yield all([takeLatest(REQUEST_INITIALFETCH, initialFetch)]);
}
