import { GRAPH_NODE, GRAPH_CHANGED } from '../../../actionTypes/Projects/Graph';

const initialState = {};

export default function Graph(state = initialState, action) {
	switch (action.type) {
		case GRAPH_NODE:
			return action.payload;
		case GRAPH_CHANGED:
			return Object.assign({}, state, action.payload);
		default:
			return state;
	}
}
