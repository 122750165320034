export function gsdata(data) {
	for (var i = 0; i < data.feed.entry.length; i++) {
		for (var key in data.feed.entry[i]) {
			if (data.feed.entry[i].hasOwnProperty(key) && key.substr(0, 4) === 'gsx$') {
				// copy the value in the key up a level and delete the original key
				data.feed.entry[i][key.substr(4)] = data.feed.entry[i][key].$t;
				delete data.feed.entry[i][key];
			}
		}
	}
	return data.feed.entry;
}

export function getObjects(obj, key, val) {
	var objects = [];
	for (var i in obj) {
		if (obj && typeof obj.hasOwnProperty == 'function') {
			if (!obj.hasOwnProperty(i)) continue;
			if (typeof obj[i] === 'object') {
				objects = objects.concat(getObjects(obj[i], key, val));
			} else if (i === key && obj[key] === val) {
				objects.push(obj);
			}
		}
	}
	return objects;
}

export function makeObject(batchResponse) {
	// var batchResponse; // This is the response from batchGet()
	// batchResponse = "{\"spreadsheetId\": \"1kEhCvvbHt-9BZgKKp-eQsy7l0AkFtZ9_7cIhvyVGLZ8\",\"valueRanges\": [{\"range\": \"data!A1:O1000\",\"majorDimension\": \"ROWS\",\"values\": [[\"id\",\"name\",\"location\",\"Description\",\"website\",\"image\",\"Address\",\"lat\",\"lng\",\"color\",\"Products\",\"Cartridges\",\"High-CBD Tincture\",\"THCa Crystalline\"],[\"1\",\"Erba\",\"West Los Angeles\",\"Erba’s philosophy is to carry only the most top-tier products, and never sacrifice quality by all means necessary. You’ll feel the difference once you step foot inside their impressive shop.\",\"http:\/\/erbacollective.com\/\",\"https:\/\/static.wixstatic.com\/media\/e4d816_49fd88e6e519486b8be81fd955a52b35~mv2.png\/v1\/fill\/w_173,h_173,al_c,usm_0.66_1.00_0.01\/e4d816_49fd88e6e519486b8be81fd955a52b35~mv2.png\",\"12320 W Pico Blvd, Los Angeles, CA 90064\\n\",\"34.028126\",\"-118.452295\",\"#7CB711\",\"Full-spectrum vape carts, THCa crystalline and tincture.\",\"TRUE\",\"FALSE\",\"TRUE\"],[\"2\",\"99 High Tide\",\"Malibu\",\"99 High Tide is Malibu's only medical marijuana dispensary. They are a visionary collective of Cannabis experts, artists, DJs, growers, healers & shamans.\",\"http:\/\/99hightide.com\/\",\"https:\/\/static.wixstatic.com\/media\/e4d816_a576375fe1104c1399cc0249b7c3818e~mv2.png\/v1\/fill\/w_173,h_173,al_c,usm_0.66_1.00_0.01\/e4d816_a576375fe1104c1399cc0249b7c3818e~mv2.png\",\"22775 Pacific Coast Hwy, Malibu, CA 90265\\n\",\"34.039466\",\"-118.671939\",\"#3A8ABD\",\"Full-spectrum vape carts and THCa crystalline.\",\"TRUE\",\"FALSE\",\"TRUE\"]]}]\n}";
	// batchResponse = JSON.parse(batchResponse); // Convert from JSON to Object
	var batchRowValues = batchResponse["values"];
	var rows = [];
	for (var i=1; i<batchRowValues.length; i++) {
	  var rowObject = {};
	  for (var j=0; j<batchRowValues[i].length; j++) {
		rowObject[batchRowValues[0][j]] = batchRowValues[i][j];
	  }
	  rows.push(rowObject);
	}
	return rows;
  }
